import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/fr"
import JobsPageTemplate from "../../templates/jobs-page"

const JobsPageFrench = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <JobsPageTemplate {...props} />
    </Layout>
  )
}
export default JobsPageFrench

export const pageQuery = graphql`
  query JobsPageFrench {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/jobs/index.fr.md/" }
    ) {
      frontmatter {
        ...JobsPage
      }
    }
  }
`
